import { ReactNode } from 'react'

const USER_PREFIX = '/[conferenceName]'
const REGISTER_PREFIX = USER_PREFIX + '/[formName]'
const USER_SCIENCE_PREFIX = '/[conferenceName]/science'
const ADMIN_PREFIX = '/admin'
const ADMIN_CONFERENCE_PREFIX = ADMIN_PREFIX + '/[conferenceName]'
const FORM_PREFIX = ADMIN_CONFERENCE_PREFIX + `/forms/[formName]`

const createRoute = (route: string, args?: Record<string, string>, query?: Record<string, string>) => {
  const isPreview = typeof window !== 'undefined' && window.location.search?.includes('preview')
  const finalQuery = isPreview ? { ...query, preview: '1' } : query
  const url = route.replace(/\[[^\]]*]/g, (v) => {
    return args?.[v.slice(1, -1)] ?? v
  })
  return {
    href: finalQuery ? url + '?' + new URLSearchParams(finalQuery).toString() : url,
  }
}

export const routes = {
  top: createRoute('/'),
  login: (returnTo?: string) => createRoute(`/login?returnTo=${returnTo}`, {}),
  googleLogin: (returnTo = '') => createRoute('/api/login/google', {}, { returnTo }),

  conferenceList: createRoute('/admin'),
  newConference: createRoute('/admin/new'),
  admin: {
    home: createRoute('/admin'),
    participant: (conferenceName: string, idInConference: number) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/[id]', {
        conferenceName,
        id: idInConference.toString(),
      }),
    participantByRealId: (conferenceName: string, id: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/[id]', { conferenceName, id: id.toString() }),
    links: (conferenceName: string) => createRoute(ADMIN_CONFERENCE_PREFIX + '/links', { conferenceName }),
    // forms: {},
    events: {
      index: (conferenceName: string) => createRoute(ADMIN_CONFERENCE_PREFIX + '/events', { conferenceName }),
      show: (conferenceName: string, id: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/events/[id]', { conferenceName, id }),
    },
    products: {
      show: (conferenceName: string, id: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/products/[id]', { conferenceName, id }),
    },
    formProducts: {
      show: (conferenceName: string, id: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/form_products/[id]', { conferenceName, id }),
    },
    participants: {
      newParticipant: (conferenceName: string, leadId?: string) =>
        createRoute(
          ADMIN_CONFERENCE_PREFIX + '/participants/new',
          { conferenceName },
          leadId ? { leadId } : {}
        ),
      import: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/import', { conferenceName }),
      printFrame: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/printFrame', { conferenceName }),
      printStickersFrame: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/printStickers', { conferenceName }),
      printVoucher: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/printVoucher', { conferenceName }),
      leads: {
        index: (conferenceName: string) =>
          createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/leads', { conferenceName }),
        show: (conferenceName: string, id: string) =>
          createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/leads/' + id, { conferenceName }),
      },
      loginAs: (conferenceName: string, id: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/login_as/' + id, { conferenceName }),
      bulkEdit: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/participants/bulk_edit', { conferenceName }),
    },
    reports: {
      index: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/reports', { conferenceName }),
      report: (conferenceName: string, type: string, id?: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/reports/' + [type, id].filter((v) => v).join('/'), {
          conferenceName,
        }),
      print: (conferenceName: string, type: string, id?: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/reports/print/' + [type, id].filter((v) => v).join('/'), {
          conferenceName,
        }),
    },
    abstract: {
      new: (conferenceName: string, participantId: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/science/abstract/new/' + participantId, {
          conferenceName,
        }),
      edit: (conferenceName: string, abstractId: number) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/science/abstract/' + abstractId + '/edit', {
          conferenceName,
        }),
    },
    clients: {
      index: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/clients', {
          conferenceName,
        }),
      show: (conferenceName: string, clientId: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/clients/' + clientId, {
          conferenceName,
        }),
    },
    vouchers: {
      index: (conferenceName: string) =>
        createRoute(ADMIN_CONFERENCE_PREFIX + '/vouchers', {
          conferenceName,
        }),
    },
    users: {
      list: createRoute(ADMIN_PREFIX + '/users'),
    },
  },
  conference: (conferenceName: string) => createRoute(ADMIN_CONFERENCE_PREFIX, { conferenceName }),
  participants: (conferenceName: string) =>
    createRoute(ADMIN_CONFERENCE_PREFIX + '/participants', { conferenceName }),

  categories: (conferenceName: string) =>
    createRoute(ADMIN_CONFERENCE_PREFIX + '/categories', { conferenceName }),
  forms: (conferenceName: string) => createRoute(ADMIN_CONFERENCE_PREFIX + '/forms', { conferenceName }),
  formEditor: (conferenceName: string, formName: string, componentName: FormTabs = FormTabs.EDITOR) =>
    createRoute(FORM_PREFIX, { conferenceName, formName }, { componentName }),

  conferenceSettings: (conferenceName: string) =>
    createRoute(ADMIN_CONFERENCE_PREFIX + '/settings', { conferenceName }),
  roomBlock: (conferenceName: string, roomBlockId: string) =>
    createRoute(ADMIN_CONFERENCE_PREFIX + '/roomblocks/[roomBlockId]', { conferenceName, roomBlockId }),
  roomBlocks: (conferenceName: string) =>
    createRoute(ADMIN_CONFERENCE_PREFIX + '/roomblocks', { conferenceName }),

  science: {
    index: (conferenceName: string, componentName: ScienceTabs = ScienceTabs.ABSTRACTS) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science', { conferenceName }, { componentName }),
    scienceSettings: (conferenceName: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/settings', { conferenceName }),
    abstract: (conferenceName: string, abstractId: number) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/abstract/[abstractId]', {
        conferenceName,
        abstractId: abstractId.toString(),
      }),
    abstractGroup: (conferenceName: string, groupId: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/groups/[groupId]', {
        conferenceName,
        groupId,
      }),
    abstractSubmitForm: (conferenceName: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/submit_form', { conferenceName }),
    abstractSubmitFormPreview: (conferenceName: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/submit_form/preview', { conferenceName }),
    importLegacy: (conferenceName: string) =>
      createRoute(ADMIN_CONFERENCE_PREFIX + '/science/import_legacy', { conferenceName }),
  },

  terms: (conferenceName: string) => createRoute(USER_PREFIX + '/terms', { conferenceName }),
  privacyPolicy: (conferenceName: string) => createRoute(USER_PREFIX + '/privacy_policy', { conferenceName }),
  clientCard: (conferenceName: string, token: string) =>
    createRoute(USER_PREFIX + '/client_card', { conferenceName }, { token }),
  register: {
    userHome: (conferenceName: string) => createRoute(USER_PREFIX, { conferenceName }),
    registrations: (conferenceName: string) =>
      createRoute(USER_PREFIX + '/registrations', { conferenceName }),
    pay: (conferenceName: string) => createRoute(USER_PREFIX + '/pay', { conferenceName }),
    form: (conferenceName: string, formName: string, isPreview = false) =>
      createRoute(REGISTER_PREFIX, { conferenceName, formName }, isPreview ? { preview: '1' } : undefined),
    complete: (conferenceName: string) => createRoute(USER_PREFIX + '/complete', { conferenceName }),
    edit: (conferenceName: string, formName: string, registrationId: string) =>
      createRoute(REGISTER_PREFIX + '/[registrationId]/edit', { conferenceName, formName, registrationId }),
    tokenLogin: (conferenceName: string, token: string) =>
      createRoute(USER_PREFIX + '/token_login', { conferenceName }, { token }),
    forgotPassword: (conferenceName: string, token: string) =>
      createRoute(USER_PREFIX + '/user/forgot', { conferenceName }, { token }),
    changePassword: (conferenceName: string) =>
      createRoute(USER_PREFIX + '/user/password', { conferenceName }),
  },
  userScience: {
    index: (conferenceName: string) => createRoute(USER_SCIENCE_PREFIX, { conferenceName }), // Does not exist
    reviews: (conferenceName: string) => createRoute(USER_SCIENCE_PREFIX + '/review', { conferenceName }),
    superList: (conferenceName: string) => createRoute(USER_SCIENCE_PREFIX + '/view', { conferenceName }),
    view: (conferenceName: string, abstractId: string) =>
      createRoute(USER_SCIENCE_PREFIX + '/view/[abstractId]', { conferenceName, abstractId }),
    complete: (conferenceName: string, abstractId: string) =>
      createRoute(USER_SCIENCE_PREFIX + '/complete/[abstractId]', { conferenceName, abstractId }),
    review: (conferenceName: string, reviewId: string) =>
      createRoute(USER_SCIENCE_PREFIX + '/review/[reviewId]', { conferenceName, reviewId }),
    list: (conferenceName: string) => createRoute(USER_SCIENCE_PREFIX + '/list', { conferenceName }),
    submit: (conferenceName: string, isPreview = false) =>
      createRoute(
        USER_SCIENCE_PREFIX + '/submit',
        { conferenceName },
        isPreview ? { preview: '1' } : undefined
      ),
    submitToGroup: (conferenceName: string, groupId: string) =>
      createRoute(USER_SCIENCE_PREFIX + '/submit/' + groupId, { conferenceName }),
    edit: (conferenceName: string, abstractId: string) =>
      createRoute(USER_SCIENCE_PREFIX + '/edit/[abstractId]', { conferenceName, abstractId }),
  },
}

export enum FormTabs {
  SETTINGS = 'settings',
  EDITOR = 'editor',
  EMAILS = 'emails',
  CATEGORIES = 'categories',
  EVENTS = 'events',
  PARTICIPANTS = 'participants',
  HOTELS = 'hotels',
}

export enum ScienceTabs {
  ABSTRACTS = 'abstracts',
  REVIEWERS = 'reviewers',
}

export type AppRoute = {
  children?: AppRoute[]
  route: { href: string }
  title: string
  icon: ReactNode
}
