/* eslint-disable @typescript-eslint/ban-types */
import { actions, Hooks } from 'react-table'
import { ReportData } from '../../../data/interfaces/ReportData'
import { UnknownObject } from '../../../data/interfaces/UnknownObject'

actions.loadReport = 'app.loadReport'

export function useLoadReport<D extends object = UnknownObject>(hooks: Hooks<D>): void {
  hooks.stateReducers.push((newState, action) => {
    if (action.type === actions.loadReport) {
      return {
        ...newState,
        ...action.value,
      }
    }
  })

  hooks.useInstance.push((instance) => {
    instance.loadReport = (report: ReportData) => {
      const { columns, groupBy, sortBy, filters } = report
      instance.dispatch({
        type: actions.loadReport,
        value: {
          hiddenColumns: instance.allColumns.map((v) => v.id).filter((v) => !columns.includes(v)),
          groupBy: groupBy,
          sortBy,
          filters: filters.map((v) => {
            if (v.value?.filterValue?.length) {
              return {
                ...v,
                value: {
                  ...v.value,
                  filterValue: v.value.filterValue.map((v: any) => (v === null ? undefined : v)),
                },
              }
            }
            return v
          }),
        },
      })
    }
  })
}
