import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import isString from 'lodash/isString'

export const useNextQueryString = ({
  param,
  compress,
  push = false,
}: {
  param: string
  compress: boolean
  push?: boolean
}) => {
  const router = useRouter()
  const setQs = useCallback(
    async (newValue: any) => {
      const jsonurl = await import('json-url')
      const jsonUrlCodec = jsonurl.default('lzstring')
      const finalValue = compress ? await jsonUrlCodec.compress(newValue) : newValue
      const method = push ? router.push : router.replace
      await method(
        { pathname: router.pathname, query: { ...router.query, [param]: finalValue } },
        undefined,
        {
          shallow: true,
        }
      )
    },
    [router, param, compress, push]
  )
  const [value, setValue] = useState<any>()

  const currentQueryValue = router.query[param]

  useEffect(() => {
    if (isString(currentQueryValue) && compress) {
      import('json-url')
        .then((jsonurl) => {
          const jsonUrlCodec = jsonurl.default('lzstring')
          return jsonUrlCodec.decompress(currentQueryValue)
        })
        .then(setValue)
    } else {
      setValue(currentQueryValue)
    }
  }, [compress, currentQueryValue])

  return useMemo(() => [value, setQs] as const, [setQs, value])
}
