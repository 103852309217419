/* eslint-disable @typescript-eslint/ban-types */
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import GlobalFilter from './GlobalFilter'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Box, Button, Chip, Divider, Tooltip } from '@material-ui/core'
import { ColumnInstance, FilterValue, TableInstance } from 'react-table'
import isFunction from 'lodash/isFunction'
import { ExportButton } from './ExportButton'
import { ComplexFilter, renderColumnFilterCell } from './SelectColumnFilter'
import { isNullOrUndefined } from '../../../data/types'
import Clear from '@material-ui/icons/Clear'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 'auto',
    marginBottom: '16px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          // color: theme.palette..main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1',
  },
}))

export type ToolbarItemsProps<T extends object> = { tableInstance: TableInstance<T> }

export type ToolbarItems<T extends object> = FunctionComponent<ToolbarItemsProps<T>>

const FiltersDisplay = ({
  allColumns,
  realFilters,
}: {
  realFilters: FilterValue[]
  allColumns: ColumnInstance<any>[]
}) => {
  return (
    <>
      {realFilters.map((filter) => {
        const { numberFilter, filterValue, textFilter } = filter.value as ComplexFilter
        const column = allColumns.find((v) => v.id === filter.id)
        if (!column) return null
        const filterIsIncludes = column.filterType === 'includesSome'

        return (
          <div key={filter.id}>
            <Typography variant={'subtitle1'}>{column?.render('Header')}: </Typography>
            {!numberFilter?.filter((v) => !isNullOrUndefined(v)).length ? null : (
              <div>
                {numberFilter.filter((v) => v).length > 1 ? (
                  <>
                    Between {renderColumnFilterCell(column, numberFilter[0])} and{' '}
                    {renderColumnFilterCell(column, numberFilter[1])}
                  </>
                ) : numberFilter[0] ? (
                  <>More than {renderColumnFilterCell(column, numberFilter[0])}</>
                ) : (
                  <>Less than {renderColumnFilterCell(column, numberFilter[1])}</>
                )}
              </div>
            )}
            {!textFilter ? null : <div>Includes &quot;{textFilter}&quot;</div>}
            {!filterValue?.length ? null : (
              <div>
                {filterIsIncludes ? 'Includes' : 'Not equal to'}{' '}
                {filterValue.length < 7
                  ? filterValue.map((v: any, i: number) => (
                      <>
                        {renderColumnFilterCell(column, v)}
                        {i < filterValue.length - 1 ? ', ' : null}
                      </>
                    ))
                  : `(${filterValue.length} items selected)`}
              </div>
            )}
            <Divider />
          </div>
        )
      })}
    </>
  )
}

export type TableToolbarOptions = {
  onlySearch: boolean
}

const TableToolbar = <T extends object>({
  title,
  extraToolbar: ExtraToolbar,
  toolbarItems,
  tableInstance,
  options,
}: {
  title?: string
  extraToolbar?: ToolbarItems<T>
  toolbarItems?: ReactNode | ToolbarItems<T>
  tableInstance: TableInstance<T>
  options?: TableToolbarOptions
}) => {
  const onlySearch = options?.onlySearch
  const { preGlobalFilteredRows, selectedFlatRows, setGlobalFilter, state, exportData, setAllFilters } = tableInstance
  const numSelected = selectedFlatRows.length
  const classes = useToolbarStyles()

  const toggleColumns = useCallback(() => tableInstance.toggleShowColumnSelector(), [tableInstance])
  const realFilters = useMemo(
    () =>
      state.filters.filter((filter) => {
        const { numberFilter, filterValue, textFilter } = filter.value as ComplexFilter
        return numberFilter?.filter((v) => !isNullOrUndefined(v)).length || filterValue?.length || textFilter
      }),
    [state.filters]
  )

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          filtered={!!realFilters.length}
        />
        {realFilters.length ? (
          <Tooltip title={<FiltersDisplay realFilters={realFilters} allColumns={tableInstance.allColumns} />}>
            <Chip color={'secondary'} label={`${realFilters.length} filters`} />
          </Tooltip>
        ) : null}
        {realFilters.length || state.globalFilter ? (
          <Box ml={2}>
            <Button
              tabIndex={4}
              size={'small'}
              startIcon={<Clear />}
              color={'primary'}
              onClick={() => {
                setGlobalFilter('')
                setAllFilters([])
              }}
            >
              Reset filter
            </Button>
          </Box>
        ) : null}
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}

        {isFunction(toolbarItems) ? toolbarItems({ tableInstance }) : toolbarItems}

        {onlySearch ? null : (
          <>
            <Box ml={2} mr={2}>
              <ExportButton tableInstance={tableInstance} />
            </Box>

            <Box mr={2}>
              <Button onClick={toggleColumns} color={'secondary'}>
                Columns
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
      {ExtraToolbar ? (
        <Toolbar>
          <ExtraToolbar tableInstance={tableInstance} />
        </Toolbar>
      ) : null}
    </>
  )
}

export default TableToolbar
