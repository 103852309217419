/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo } from 'react'
import MaUTable from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableToolbar, { TableToolbarOptions, ToolbarItems } from './TableToolbar'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
import {
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  SortByFn,
  TableOptions,
  TableState,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { TableBody } from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import isString from 'lodash/isString'
import isEqual from 'lodash/isEqual'
import { ComplexFilter, SelectColumnFilter } from './SelectColumnFilter'
import { FILTER_TYPES } from './FilterTypes'
import { useLoadReport } from './useLoadReport'
import { useShowColumnSelector } from './useShowColumnSelector'
import { useSelectionColumn } from './useSelectionColumn'
import { useExportData } from 'react-table-plugins'
import { useNextQueryString } from '../../common/useNextQueryString'
import { dataFromInstance } from './DataFromInstance'
import { UnknownObject } from '../../../data/interfaces/UnknownObject'
import dynamic from 'next/dynamic'
import { useComponentState } from '../../layout/UseComponentState'
import { getAggregate } from './getAggregate'
import Fuse from 'fuse.js'
import get from 'lodash/get'

// import { ColumnsPanel } from './ColumnsPanel'
const ColumnsPanel = dynamic(() => import('./ColumnsPanel'))

const useStyles = makeStyles((theme) => ({
  tableBody: {
    width: '100%',
    height: '100%',
    display: 'block',
    // overflowY: 'auto',
    // overflowX: 'visible',
    overflow: 'hidden',
  },
  row: {
    whiteSpace: 'nowrap',
    // display: 'flex',
  },
  groupByExpand: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  resizer: {
    background: 'transparent',
    display: 'inline-block',
    height: '100%',
    cursor: 'col-resize',
    transform: 'translateX(16px)',
    flexShrink: 0,
    position: 'absolute',
    right: '11px',
    top: 0,
    '&:after': {
      content: '""',
      borderRight: '1px solid ' + theme.palette.grey.A100,
      margin: '0 5px',
      height: '100%',
      display: 'block',
    },
  },
  tableCell: {
    flexShrink: 0,
    '&:hover $headerMenuTrigger': {
      opacity: 1,
    },
  },
  th: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    flexShrink: 0,
  },
  thSortHeader: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
  },
  headerMenuTrigger: {
    opacity: 0,
  },
  thContent: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '2px 0',
  },
  td: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableHead: {
    overflow: 'hidden',
    flexShrink: 0,
    paddingRight: '15px',
  },
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    overflow: 'hidden',
  },
  columnsPanel: {
    width: '15%',
    flexShrink: 0,
    overflow: 'auto',
  },
  tableContainer: {
    flex: 1,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  selected:
    theme.palette.type === 'light'
      ? {
          // color: theme.palette..main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}))

type EnhancedTableProps<T extends object = UnknownObject> = {
  tableId: string // This is for storing state while changing pages
  columns: Column<T>[]
  data: T[]
  skipPageReset?: boolean
  onSelectedChange?: (selectedRows: string[]) => void
  toolbarTitle?: string
  tableOptions?: Partial<TableOptions<T>>
  Expanded?: React.FC<{ row: Row<T> }>
  extraToolbar?: ToolbarItems<T>
  toolbarItems?: ToolbarItems<T>
  initialState?: Partial<TableState>
  toolbarOptions?: TableToolbarOptions
  hideSelection?: boolean
  hideHeader?: boolean
  hideHeaderFilters?: boolean
}

const Header = ({ column, showResizer }: { column: HeaderGroup<any>; showResizer: boolean }) => {
  const classes = useStyles()
  const toggleSort = useCallback(() => column.toggleSortBy(), [column])

  return (
    <TableCell
      padding={column.id === 'selection' ? 'checkbox' : 'normal'}
      component={'div'}
      {...column.getHeaderProps()}
      title={isString(column.Header) ? column.Header : undefined}
      className={classes.tableCell}
    >
      <div className={classes.th}>
        <div onClick={column.canSort ? toggleSort : undefined} className={classes.thSortHeader}>
          <div className={classes.thContent}>{column.render('Header')}</div>

          {column.isSorted ? (
            <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
          ) : null}
        </div>

        {!showResizer || !column.canResize ? null : (
          <span
            {...column.getResizerProps()}
            className={classes.resizer}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </TableCell>
  )
}

function Table<T extends { id: string }>({
  columns,
  data,
  toolbarTitle,
  onSelectedChange,
  tableOptions = {},
  toolbarItems,
  extraToolbar,
  tableId,
  initialState: inputInitialState = {},
  toolbarOptions,
  hideSelection,
  hideHeader,
  hideHeaderFilters,
}: EnhancedTableProps<T>) {
  const defaultColumn: Partial<Column<T>> = useMemo(
    () => ({
      width: 150,
      Filter: SelectColumnFilter,
      Footer: (column: ColumnInstance<T>) => {
        const aggregateFn = getAggregate(column)

        if (aggregateFn) {
          const groupedValues = column.filteredRows.map((row) => row.values[column.id])
          return column.render('Aggregated', { value: aggregateFn(groupedValues), row: {} as any })
        }
        return null
      },
      filter: (rows, columnIds, complexFilter) => {
        const { filterValue, filterType, textFilter, numberFilter } = (complexFilter || {}) as ComplexFilter

        const filter = FILTER_TYPES[filterType || 'excludes']
        let res = rows
        if (filter && filterValue?.length) {
          res = filter(res, columnIds, filterValue)
        }
        if (textFilter) {
          res = FILTER_TYPES.text(res, columnIds, textFilter)
        }
        if (numberFilter) {
          res = FILTER_TYPES.inRange(res, columnIds, numberFilter)
        }

        return res
      },
    }),
    []
  )
  const [initialState, setInitialState] = useComponentState<TableState | undefined>('table_' + tableId)

  const stateReducer = useCallback((newState, action) => {
    if (action.type === 'init') {
      return {
        ...newState,
        sortBy: [{ id: 'id', desc: true }],
        ...inputInitialState,
        ...initialState,
      }
    } else {
      setInitialState(newState)
    }
    return newState
    // eslint-disable-next-line
  }, [])

  const sortTypes: Record<string, SortByFn<T>> = useMemo(
    () => ({
      datetime: (rowA, rowB, columnId) => {
        const valueA = rowA.values[columnId]
        const valueB = rowB.values[columnId]
        if (valueA === valueB) return 0
        if (!valueA) return -1
        if (!valueB) return 1
        return valueA > valueB ? 1 : -1
      },
    }),
    []
  )
  const options: TableOptions<T> = useMemo(
    () => ({
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetRowState: false,
      autoResetGlobalFilter: false,
      autoResetHiddenColumns: false,
      initialState: inputInitialState,
      stateReducer,
      sortTypes: sortTypes,
      globalFilter: (rows, keys, v) => {
        if (!rows?.length || !keys?.length) return []
        const naiveFilter = rows.filter((row) =>
          Object.values(row.values).some((value) => (value?.toString().toLowerCase() || '').includes(v?.trim().toLowerCase()))
        )

        if (naiveFilter.length) return naiveFilter

        const fuse = new Fuse(rows, {
          getFn: (obj, path) => get(obj.values, path)?.toString() || '',
          // keys: ['name', { name: 'shortName', weight: 500 }],
          keys,
          threshold: 0.4,
          shouldSort: true,
          isCaseSensitive: false,
          includeMatches: false,
          includeScore: false,
        })
        return fuse.search(v).map((v) => v.item)
      },
      // useControlledState: useControlledState,
      ...tableOptions,
    }),
    [columns, data, defaultColumn, inputInitialState, sortTypes, stateReducer, tableOptions]
  )
  const tableInstance = useTable(
    options,
    // useBlockLayout,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    // useColumnTreeOrder,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    useResizeColumns,
    useExportData,
    hideSelection ? () => {} : useSelectionColumn,
    useLoadReport,
    useShowColumnSelector // Last because of reset function
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { selectedRowIds },
  } = tableInstance

  const [qs, setQs] = useNextQueryString({ param: 'q', compress: true })
  // useEffect(() => {
  //   setQs(dataFromInstance(tableInstance))
  // }, [tableInstance])

  useEffect(() => {
    if (qs && !isEqual(qs, dataFromInstance(tableInstance))) {
      tableInstance.loadReport(qs)
    }
    // eslint-disable-next-line
  }, [qs])

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selectedFlatRows.map((v) => v.original.id))
    }
    // eslint-disable-next-line
  }, [onSelectedChange, selectedRowIds])

  const classes = useStyles()
  const RowEl = useMemo(
    () =>
      ({ index, data, style }: ListChildComponentProps) => {
        const row: Row<any> = data[index]
        prepareRow(row)
        return (
          <div style={style}>
            <TableRow
              component={'div'}
              {...row.getRowProps()}
              // style={style}
              className={row.isSelected ? classes.selected : undefined}
            >
              {row.cells.map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    component={'div'}
                    {...cell.getCellProps({ padding: cell.column.padding })}
                    className={classes.td}
                  >
                    {cell.isGrouped ? (
                      <>
                        <div className={classes.groupByExpand} {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </div>{' '}
                        {cell.render('Cell')} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : (
                      cell.render('Cell')
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </div>
        )
      },
    [prepareRow, classes]
  )

  const lastHeaderGroup = headerGroups[headerGroups.length - 1]
  return (
    <div className={classes.outerContainer}>
      <TableToolbar<T>
        title={toolbarTitle}
        extraToolbar={extraToolbar}
        toolbarItems={toolbarItems}
        tableInstance={tableInstance}
        options={toolbarOptions}
      />
      <div className={classes.container}>
        <TableContainer className={classes.tableContainer}>
          <ScrollSync>
            <MaUTable component={'div'} {...getTableProps()} className={classes.table}>
              {hideHeader ? null : (
                <ScrollSyncPane>
                  <TableHead component={'div'} className={classes.tableHead}>
                    {headerGroups.map((headerGroup, i) => {
                      const isLastRow = i === headerGroups.length - 1
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow component={'div'} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <Header key={column.id} column={column} showResizer={isLastRow} />
                          ))}
                        </TableRow>
                      )
                    })}
                    {hideHeaderFilters ? null : (
                      <TableRow component={'div'} {...lastHeaderGroup.getHeaderGroupProps()}>
                        {lastHeaderGroup.headers.map((column) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableCell
                            component={'div'}
                            {...column.getHeaderProps()}
                            className={classes.tableCell}
                          >
                            {column.canFilter ? column.render('Filter') : null}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </TableHead>
                </ScrollSyncPane>
              )}

              <TableBody component={'div'} {...getTableBodyProps()} className={classes.tableBody}>
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <ScrollSyncPane>
                        <List
                          // className={classes.list}
                          height={height || 300}
                          width={width}
                          itemCount={rows.length}
                          itemSize={53}
                          itemKey={(index, data) => data[index]?.id}
                          itemData={rows}
                        >
                          {RowEl}
                        </List>
                      </ScrollSyncPane>
                    )
                  }}
                </AutoSizer>
              </TableBody>
            </MaUTable>
          </ScrollSync>
        </TableContainer>

        {tableInstance.state.showColumnSelector ? (
          <div className={classes.columnsPanel}>
            <ColumnsPanel tableInstance={tableInstance} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Table
