import React, { ReactNode, useMemo } from 'react'
import { Box, BoxProps, Breadcrumbs, IconButton, Paper, styled, Typography } from '@material-ui/core'
import Head from 'next/head'
import { AppRoute } from '../../routes'
import { useRouter } from 'next/router'
import { useAppRoutes } from '../../useAppRoutes'
import Link from 'next/link'
import { useQuery } from '@apollo/client/react/hooks'
import { SidebarDataDocument } from '../layout/AppSidebar.generated'
import { useCurrentConferenceNameAllowEmpty } from './useCurrent'
import ArrowBackIosRounded  from '@material-ui/icons/ArrowBackIosRounded'

const findInTree = (path: string, appRoutes: AppRoute[]): AppRoute[] | undefined => {
  for (const current of appRoutes) {
    const currentRoute = current.route.href.split('?')[0]
    if (path === currentRoute) {
      return []
    }
    if (current.children) {
      const res = findInTree(path, current.children)
      if (res) {
        return [current, ...res]
      }
    }
    if (path.includes(currentRoute)) {
      return [current]
    }
  }
}

type PageTitleProps = {
  title: ReactNode
  Button?: ReactNode
  children: ReactNode
  subtitle?: ReactNode
  boxProps?: BoxProps
  pageTitle?: string
  routes?: AppRoute[]
  prevHref?: string
}

const StyledBreadCrumbs = styled(Breadcrumbs)({
  '@media print': {
    display: 'none',
  },
})

export const StyledBox = styled(Box)({
  '@media print': {
    display: 'block',
  },
})

export const CleanPageTitle = ({
  title,
  Button,
  children,
  subtitle,
  boxProps,
  pageTitle,
  routes: currentRoutes,
  prevHref,
}: PageTitleProps) => {
  return (
    <>
      <Head>
        <title>{pageTitle || title || null}</title>
      </Head>
      <StyledBox pr={4} pl={4} overflow={'auto'} flex={1} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} mt={4}>
          <Box flex={1}>
            <Box display={'flex'} alignItems={'center'}>
              {prevHref ? (
                <Link href={prevHref} passHref>
                  <Box component={IconButton} marginLeft={'-24px'} padding={0}>
                    <ArrowBackIosRounded />
                  </Box>
                </Link>
              ) : null}
              <Typography variant={'h3'}>{title}</Typography>
            </Box>
            <Typography variant={'h6'}>{subtitle}</Typography>
            <StyledBreadCrumbs separator={'>'}>
              {currentRoutes?.map((route) => (
                <Link key={route.route.href} {...route.route}>
                  {route.title}
                </Link>
              ))}
            </StyledBreadCrumbs>
          </Box>
          <Box>{Button}</Box>
        </Box>

        <Box mt={4} flex={1} {...boxProps}>
          {children}
        </Box>
      </StyledBox>
    </>
  )
}

export const PageTitle = ({ children, ...props }: PageTitleProps) => {
  const conferenceName = useCurrentConferenceNameAllowEmpty()
  const { data } = useQuery(SidebarDataDocument, {
    variables: { conferenceName: conferenceName || '' },
    skip: !conferenceName,
  })
  const conference = data?.conference
  const appRoutes = useAppRoutes()
  const { asPath } = useRouter()
  const pathNameWithout = asPath.split('?')[0]
  const currentRoutes =
    useMemo(() => findInTree(pathNameWithout, appRoutes), [pathNameWithout, appRoutes]) || []
  const pageTitle =
    [props.title, conference?.shortName].filter((v) => v).join(' | ') || props.title?.toString() || undefined
  const prevHref = currentRoutes[currentRoutes.length - 1]?.route.href

  return (
    <CleanPageTitle pageTitle={pageTitle} {...props} routes={currentRoutes} prevHref={prevHref}>
      {children}
    </CleanPageTitle>
  )
}

export const FullHeightPageTitle = ({ boxProps: boxPropsInput, children, ...props }: PageTitleProps) => {
  const boxProps = useMemo(
    () => ({
      overflow: 'hidden',
      ...boxPropsInput,
    }),
    [boxPropsInput]
  )

  return (
    <PageTitle {...props} boxProps={boxProps}>
      <Box component={Paper} height={'100%'} overflow={'hidden'}>
        {children}
      </Box>
    </PageTitle>
  )
}
