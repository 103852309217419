import { useCallback, useEffect, useState } from 'react'
import { useAppContext } from './AppContext'

export const useComponentState = <T extends any>(key: string): [T | undefined, (v: T) => void] => {
  const { componentStates, setComponentStates } = useAppContext()
  const [localState, setLocalState] = useState(componentStates?.[key])
  useEffect(() => {
    if (componentStates?.[key] !== localState) {
      setLocalState(componentStates?.[key])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentStates])
  const setState = useCallback(
    (newValue: any) => {
      setComponentStates?.((v) => ({ ...v, [key]: newValue }))
    },
    [key, setComponentStates]
  )
  return [localState, setState]
}
