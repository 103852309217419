import { TableInstance } from 'react-table'
import { ReportData } from '../../../data/interfaces/ReportData'

export const dataFromInstance = ({
  visibleColumns,
  state: { groupBy, hiddenColumns, sortBy, columnOrder, filters, expanded },
}: TableInstance<any>): ReportData => {
  return {
    expanded: false,
    sortBy,
    filters,
    groupBy,
    columns: visibleColumns.map((v) => v.id),
  }
}
