import { TableInstance } from 'react-table'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback } from 'react'
import ImportExport from '@material-ui/icons/ImportExport'
import { useRouter } from 'next/router'
import { dataFromInstance } from './DataFromInstance'
import copyToClipboard from 'copy-to-clipboard'
import { addLinkDomain } from '../../../lib/addLinkDomain'
import { ExportFileType } from '../../../data/interfaces/ExportFileType'
import { useCurrentConference } from '../../common/useCurrent'

export const ExportButton = ({ tableInstance }: { tableInstance: TableInstance<any> }) => {
  const menuState = usePopupState({ variant: 'popover', popupId: 'exportMenu' })
  const conference = useCurrentConference()

  const doExport = useCallback(
    async (fileType: ExportFileType, allData = false) => {
      const { exportData } = await import('./ExportTools')
      return exportData({
        tableInstance,
        fileName: conference?.shortName || 'report',
        fileType,
        columns: allData
          ? tableInstance.allColumns.map((v) => v.id)
          : tableInstance.visibleColumns.map((v) => v.id),
        conferenceName: conference?.name || '',
        title: 'Report',
        expanded: false,
      })
    },
    [conference, tableInstance]
  )
  const { asPath } = useRouter()

  return (
    <>
      <Button {...bindTrigger(menuState)} startIcon={<ImportExport />}>
        Export
      </Button>
      <Menu {...bindMenu(menuState)}>
        <MenuItem onClick={() => doExport('xlsx', true)}>All data (Excel)</MenuItem>
        <MenuItem onClick={() => doExport('xlsx')}>Current view (Excel)</MenuItem>
        <MenuItem onClick={() => doExport('docx-table')}>Current view (Word Table)</MenuItem>
        <MenuItem onClick={() => doExport('docx-text')}>Current view (Word Text)</MenuItem>
        <MenuItem
          onClick={async () => {
            const jsonurl = await import('json-url')
            const jsonUrlCodec = jsonurl.default('lzstring')
            const url = addLinkDomain(
              asPath.split('?')[0] + '?q=' + (await jsonUrlCodec.compress(dataFromInstance(tableInstance)))
            )
            copyToClipboard(url)
          }}
        >
          Copy url to clipboard
        </MenuItem>
      </Menu>
    </>
  )
}
