import { useQuery } from '@apollo/client/react/hooks'
import TextFormat from '@material-ui/icons/TextFormat'
import Shop from '@material-ui/icons/Shop'
import SettingsApplications from '@material-ui/icons/SettingsApplications'
import Settings from '@material-ui/icons/Settings'
import Receipt from '@material-ui/icons/Receipt'
import Person from '@material-ui/icons/Person'
import LocalLibrary from '@material-ui/icons/LocalLibrary'
import Link from '@material-ui/icons/Link'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import Hotel from '@material-ui/icons/Hotel'
import Event from '@material-ui/icons/Event'
import EmojiPeople from '@material-ui/icons/EmojiPeople'
import Assignment from '@material-ui/icons/Assignment'
import { Avatar } from '@material-ui/core'
import React, { useMemo } from 'react'
import { AppRoute, FormTabs, routes } from './routes'
import { SidebarDataDocument } from './components/layout/AppSidebar.generated'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useCurrentConferenceNameAllowEmpty } from './components/common/useCurrent'

export const useStyles = makeStyles((theme: Theme) => ({
  sidebarAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))

export const useAppRoutes = (): AppRoute[] => {
  const conferenceName = useCurrentConferenceNameAllowEmpty()
  const { data } = useQuery(SidebarDataDocument, {
    variables: { conferenceName: conferenceName || '' },
    skip: !conferenceName,
  })
  const classes = useStyles()
  const conference = data?.conference

  return useMemo(() => {
    if (!conferenceName) return []
    return [
      {
        route: routes.participants(conferenceName),
        title: 'Participants',
        icon: <Person />,
        children: [
          {
            route: routes.admin.participants.leads.index(conferenceName),
            title: 'Leads',
            icon: <EmojiPeople />,
          },
        ],
      },
      { route: routes.conferenceSettings(conferenceName), title: 'Settings', icon: <Settings /> },
      { route: routes.admin.events.index(conferenceName), title: 'Events', icon: <Event /> },
      { route: routes.roomBlocks(conferenceName), title: 'Room Blocks', icon: <Hotel /> },
      {
        route: routes.forms(conferenceName),
        title: 'Forms',
        icon: <Shop />,
        children: conference?.forms.map((form) => ({
          route: routes.formEditor(conferenceName, form.shortName, FormTabs.SETTINGS),
          title: form.name,
          icon: (
            <Avatar variant={'square'} className={classes.sidebarAvatar}>
              {form.shortName.slice(0, 1)}
            </Avatar>
          ),
        })),
      },
      {
        route: routes.science.index(conferenceName),
        title: 'Scientific',
        icon: <LocalLibrary />,

        children: [
          {
            route: routes.science.abstractSubmitForm(conferenceName),
            title: 'Abstract Form',
            icon: <TextFormat />,
          },
          {
            route: routes.science.scienceSettings(conferenceName),
            title: 'Science Settings',
            icon: <SettingsApplications />,
          },
        ],
      },
      {
        route: routes.admin.reports.index(conferenceName),
        title: 'Reports',
        icon: <Assignment />,
      },
      { route: routes.admin.links(conferenceName), title: 'User Links', icon: <Link /> },
      {
        route: routes.admin.vouchers.index(conferenceName),
        title: 'Vouchers',
        icon: <AssignmentIndIcon />,
      },
      { route: routes.admin.clients.index(conferenceName), title: 'Clients', icon: <Receipt /> },
    ]
  }, [classes.sidebarAvatar, conference?.forms, conferenceName])
}
