/* eslint-disable @typescript-eslint/ban-types */
import { actions, Hooks, TableState } from 'react-table'
import { useState } from 'react'
import { UnknownObject } from '../../../data/interfaces/UnknownObject'

actions.toggleShowColumnSelector = 'toggleShowColumnSelector'
actions.resetState = 'app.resetState'

export function useShowColumnSelector<D extends object = UnknownObject>(hooks: Hooks<D>): void {
  hooks.useInstance.push((instance) => {
    instance.toggleShowColumnSelector = (value?: boolean) =>
      instance.dispatch({ type: actions.toggleShowColumnSelector, value })
  })
  const [initState, setInitState] = useState<TableState>()
  hooks.stateReducers.push(({ showColumnSelector, ...state }, action) => {
    if (action.type === actions.init) {
      setInitState(state)
      return {
        showColumnSelector: false,
        ...state,
      }
    }

    if (action.type === actions.toggleShowColumnSelector) {
      return {
        ...state,
        showColumnSelector: action.value ?? !showColumnSelector,
      }
    }
    if (action.type === actions.resetState && initState) {
      return { ...state, ...initState }
    }
  })
}
