import { FilterType } from 'react-table'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'
import { isNullOrUndefined } from '../../../data/types'

function includes(rowValue: any, filterValue: any) {
  return String(rowValue || '')
    .toLowerCase()
    .includes(String(filterValue).toLowerCase())
}

export const FILTER_TYPES: Record<string, FilterType<any>> = {
  text: (rows, ids, filterValue) => {
    rows = rows.filter((row) => {
      return ids.some((id) => {
        const filterValueAccessor = row.cells.find((c) => c.column?.id === id)?.column.filterValueAccessor
        const rawRowValue = filterValueAccessor ? filterValueAccessor(row.values[id]) : row.values[id]
        const rowValue = isString(rawRowValue) ? rawRowValue?.toLowerCase() : rawRowValue
        return Array.isArray(rowValue)
          ? rowValue.some((v) => includes(v, filterValue))
          : includes(isObject(rowValue) ? Object.values(rowValue) : rowValue, filterValue)
      })
    })
    return rows
  },
  includesSome: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return rowValue && filterValue.some((val: any) => rowValue.includes(val))
      })
    })
  },
  excludes: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return !filterValue?.some((f: any) => f === rowValue || isEqual(f, rowValue))
      })
    })
  },
  excludesSome: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return !rowValue?.some((v: any) => filterValue.some((f: any) => isEqual(f, v)))
      })
    })
  },
  inRange: (rows, ids, filterValue) => {
    if (!filterValue?.length) return rows
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        const [lower, higher] = filterValue
        if (!isNullOrUndefined(lower) && rowValue < lower) return false
        if (!isNullOrUndefined(higher) && rowValue > higher) return false
        return true
      })
    })
  },
}
