import { Checkbox as MuiCheckbox, FormControlLabel, FormControlLabelProps } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
}))
export const AppCheckbox = ({
  formControlLabelProps,
  label,
  ...props
}: CheckboxProps & {
  label: ReactNode
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>
}) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.root}
      control={<MuiCheckbox {...props} />}
      {...formControlLabelProps}
      label={label}
    />
  )
}
