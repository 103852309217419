/* eslint-disable @typescript-eslint/ban-types */
import { CellProps, Hooks } from 'react-table'
import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { UnknownObject } from '../../../data/interfaces/UnknownObject'

const useStyles = makeStyles((theme) => ({
  selectionCheckbox: {
    height: '100%',
  },
}))

export function useSelectionColumn<D extends object = UnknownObject>(hooks: Hooks<D>): void {
  const classes = useStyles()
  hooks.allColumns.push((columns) => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox {...getToggleAllRowsSelectedProps()} />,
      Cell: ({ row }: CellProps<D>) => {
        return <Checkbox {...row.getToggleRowSelectedProps({ className: classes.selectionCheckbox })} />
      },
      Aggregated: () => null,
      width: 50,
      disableResizing: true,
      canExport: false,
      isVisible: true,
      exportValue: null,
      padding: 'checkbox',
    },
    ...columns,
  ])
}
