import { ColumnInstance, DefaultFilterTypes, FilterProps, FilterValue } from 'react-table'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import throttle from 'lodash/throttle'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import { Button, IconButton, List, ListItem, Popover, Slider, TextField } from '@material-ui/core'
import FilterList from '@material-ui/icons/FilterList'
import { AppCheckbox } from '../../common/AppCheckbox'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { UnknownObject } from '../../../data/interfaces/UnknownObject'

export type ComplexFilter = {
  filterType: DefaultFilterTypes
  filterValue: FilterValue
  textFilter?: string
  numberFilter?: number[]
}

const useStyles = makeStyles<Theme, { active?: boolean }>((theme) => ({
  filterColumnFilter: {
    display: 'flex',
    flexDirection: 'row',
    opacity: ({ active }) => (active ? 1 : '0.4'),
    '&:hover, &:focus, &:active': {
      opacity: 1,
    },
  },
  filterTrigger: {
    flex: 1,
    textAlign: 'end',
  },
  slider: {
    margin: 0,
    marginRight: theme.spacing(2),
  },
}))

const FilterSlider = ({
  options,
  onChange,
  render,
  filterValue,
}: {
  options: (number | undefined)[]
  onChange: (value: any) => void
  render: (value: any) => ReactNode
  FilterValueCell?: any
  filterValue: any
}) => {
  const filtered = options.filter((v) => isNumber(v)) as number[]
  const min = Math.min(...filtered)
  const max = Math.max(...filtered)
  const getClampedValue = useCallback(
    ([lower, higher]: [number, number]) => {
      return [Math.max(min, lower || min), Math.min(max, higher || max)]
    },
    [min, max]
  )
  const classes = useStyles({})
  const [value, setValue] = useState([min, max])

  useEffect(() => {
    if (min !== max && filterValue !== value && (filterValue?.length || value[0] > min || value[1] < max)) {
      setValue(getClampedValue(filterValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, max, min])

  const step = Math.max(Math.pow(10, (max - min).toString().length) / 100, 1)

  return (
    <Slider
      disabled={min === max || !isFinite(min) || !isFinite(max)}
      className={classes.slider}
      min={min}
      max={max}
      step={step}
      key={1}
      valueLabelDisplay="auto"
      valueLabelFormat={(v) => render(v)}
      value={value}
      onChange={(e, value) => {
        if (isNumber(value) || max == min) return
        const clampedValue = getClampedValue(value as any)
        setValue(clampedValue)

        const [newLow, newHigh] = clampedValue
        onChange([newLow === min ? undefined : newLow, newHigh === max ? undefined : newHigh])
      }}
      marks={[
        ...(value[0] > min ? [{ label: render(value[0]), value: value[0] }] : []),
        ...(value[1] < max ? [{ label: render(value[1]), value: value[1] }] : []),
      ]}
    />
  )
}

export const NONE_TEXT = '(None)'

export const renderColumnFilterCell = (column: ColumnInstance<any>, value: any) => {
  const res = column.FilterValueCell ? column.render('FilterValueCell', { value: value ?? '' }) : value
  return res?.toString() ? res : NONE_TEXT
}

export const SelectColumnFilter = <T extends UnknownObject>({ column }: FilterProps<T>) => {
  const { filterValue: complexFilter = {}, setFilter, preFilteredRows, id, filterType } = column
  const { filterValue = [], textFilter = '', numberFilter = [] } = complexFilter as ComplexFilter
  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' })

  const options = React.useMemo(() => {
    return uniqBy(
      preFilteredRows.flatMap((row) => row.values[id]),
      (v) => JSON.stringify(v)
    )
      .filter((option) => {
        return (
          !textFilter || !isString(option) || option.toLowerCase().includes(textFilter?.toLowerCase() || '')
        )
      })
      .map((v) => (v === null ? undefined : v))
      .sort()
  }, [id, preFilteredRows, textFilter])
  const isNumeric = options.every((v) => !v || isNumber(v))
  const classes = useStyles({
    active: filterValue.length || textFilter || numberFilter?.length || popupState.isOpen,
  })

  const indeterminate = useMemo(() => {
    if (!filterValue) return false
    return filterValue.length !== options.length && filterValue.length > 0
  }, [options, filterValue])

  const onChange = useMemo(
    () =>
      throttle((value) => setFilter({ ...complexFilter, numberFilter: value }), 1000, {
        leading: false,
        trailing: true,
      }),
    [setFilter, complexFilter]
  )
  const filterIsIncludes = filterType === 'includesSome'
  let currentFilterItems: unknown[]
  if (filterIsIncludes) {
    currentFilterItems = filterValue?.length ? options.filter((v) => filterValue.includes(v)) : []
  } else {
    currentFilterItems = filterValue?.length ? options.filter((v) => !filterValue.includes(v)) : options
  }

  const renderFilterCell = useCallback((value: any) => renderColumnFilterCell(column, value), [column])

  return (
    <div className={classes.filterColumnFilter}>
      {filterValue?.length ? (
        '(' + currentFilterItems.length + ' Selected)'
      ) : isNumeric && !textFilter ? (
        <FilterSlider
          options={options as any}
          onChange={onChange}
          render={renderFilterCell}
          filterValue={numberFilter}
        />
      ) : (
        <TextField
          size={'small'}
          margin={'none'}
          type={'search'}
          fullWidth
          onChange={(e) => setFilter({ ...complexFilter, textFilter: e.target.value })}
        />
      )}
      <div className={classes.filterTrigger}>
        <IconButton size={'small'} {...bindTrigger(popupState)}>
          <FilterList fontSize={'small'} />
        </IconButton>
      </div>
      <Popover {...bindPopover(popupState)} keepMounted={false}>
        <List>
          {filterIsIncludes ? null : (
            <ListItem>
              <AppCheckbox
                size={'small'}
                label={'All'}
                checked={!filterValue?.length}
                indeterminate={indeterminate}
                tabIndex={-1}
                disableRipple
                onChange={(event, checked) => {
                  setFilter({
                    ...complexFilter,
                    filterType,
                    filterValue: checked ? [] : Array.from(options),
                  })
                }}
              />
            </ListItem>
          )}
          {options.map((option, i) => {
            const includes = filterValue.includes(option)
            return (
              <ListItem key={JSON.stringify(option) || i}>
                <AppCheckbox
                  label={renderFilterCell(option)}
                  size={'small'}
                  checked={filterIsIncludes ? includes : !includes}
                  tabIndex={-1}
                  disableRipple
                  onChange={(e, checked) => {
                    setFilter({
                      ...complexFilter,
                      filterType,
                      filterValue:
                        checked === filterIsIncludes
                          ? [...filterValue, option]
                          : filterValue.filter((v: any) => v !== option),
                    })
                  }}
                />
              </ListItem>
            )
          })}
          <ListItem>
            <Button onClick={() => setFilter({})}>Reset</Button>
          </ListItem>
        </List>
      </Popover>
    </div>
  )
}
