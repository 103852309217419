import * as Types from '../../../generated/graphql';

import { FormDataFragment } from '../../data/fragments.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FormDataFragmentDoc } from '../../data/fragments.generated';
export type SidebarDataQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type SidebarDataQuery = (
  { __typename?: 'Query' }
  & { conference?: Types.Maybe<(
    { __typename?: 'Conference' }
    & Pick<Types.Conference, 'id' | 'name' | 'shortName'>
    & { forms: Array<(
      { __typename?: 'Form' }
      & FormDataFragment
    )> }
  )> }
);


export const SidebarDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SidebarData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"forms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"shortName"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormData"}}]}}]}}]}},...FormDataFragmentDoc.definitions]} as unknown as DocumentNode<SidebarDataQuery, SidebarDataQueryVariables>;